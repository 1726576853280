import { createReducer, on } from '@ngrx/store';
import {
  getPlaylistsFailure,
  getPlaylistsSuccess,
  selectClip,
  selectPlaylist,
} from './playlist.actions';
import { Playlist, PlaylistClip } from 'src/app/core/types/playlist.types';

export interface PlaylistState {
  data: Playlist[];
  selectedPlaylist: Playlist | undefined;
  selectedClip: PlaylistClip | undefined;
  error: any;
}

export const initialPlaylistState: PlaylistState = {
  data: [],
  selectedPlaylist: undefined,
  selectedClip: undefined,
  error: null,
};

export const playlistReducer = createReducer(
  initialPlaylistState,
  on(getPlaylistsSuccess, (state, { data }) => ({
    ...state,
    data,
    error: null,
  })),
  on(getPlaylistsFailure, (state, { error }) => ({
    ...state,
    error,
    data: [],
  })),
  on(selectPlaylist, (state, { playlist }) => ({
    ...state,
    selectedPlaylist: playlist,
  })),
  on(selectClip, (state, { clip }) => ({ ...state, selectedClip: clip }))
);
