export const deepEqual = (x: any, y: any): boolean => {
  if (x === y) {
    return true;
  } else if (
    (x instanceof RegExp && y instanceof RegExp) ||
    (x instanceof Function && y instanceof Function)
  ) {
    return x.toString() === y.toString();
  } else if (
    typeof x === 'object' &&
    x != null &&
    typeof y === 'object' &&
    y != null
  ) {
    const [xKeys, yKeys] = [Object.keys(x), Object.keys(y)];

    if (xKeys.length !== yKeys.length) {
      return false;
    }

    return Array.from(new Set([...xKeys, ...yKeys])).every((key) =>
      deepEqual(x[key], y[key])
    );
  } else {
    return false;
  }
};

export const isEmptyObject = (item: any): boolean =>
  typeof item === 'object' && Object.keys(item).length === 0;
