import { Nullable } from '@sportiananalysis/ui/lib/models/type-helpers.models';

export const getPathname = () =>
  document?.location
    ? document.location?.pathname + document.location?.search
    : '';

export const appendBuildInfo = (
  buildVersion: string,
  buildNum: string,
  buildDate: string
) => {
  const $buildInfo = document.createComment(
    ' Version ' +
      buildVersion +
      ' | Build ' +
      buildNum +
      ' | Release ' +
      buildDate +
      ' '
  );
  const $head = document.getElementsByTagName('head')[0];
  const $headFirstChild = $head.firstChild;
  $head.insertBefore($buildInfo, $headFirstChild);
};

export const getScrollParent = (
  document: { body: any },
  element: Element,
  includeHidden = false
) => {
  let style = getComputedStyle(element);
  const excludeStaticParent = style.position === 'absolute';
  const overflowRegex = includeHidden
    ? /(auto|scroll|hidden)/
    : /(auto|scroll)/;

  if (style.position === 'fixed') {
    return document.body;
  }
  for (
    let parent: Element | null = element;
    (parent = parent.parentElement);

  ) {
    style = getComputedStyle(parent);
    if (excludeStaticParent && style.position === 'static') {
      continue;
    }
    if (
      overflowRegex.test(style.overflow + style.overflowY + style.overflowX)
    ) {
      return parent;
    }
  }

  return document.body;
};

export const findInDOM = (
  scopeElement: HTMLElement,
  selector: string
): HTMLElement[] =>
  scopeElement ? Array.from(scopeElement.querySelectorAll(selector)) : [];
export const findSingleInDOM = (
  scopeElement: Nullable<HTMLElement>,
  selector: string
): Nullable<HTMLElement> => scopeElement?.querySelector(selector);
export const getFocusableElements = (element: HTMLElement) => {
  let focusableElements = findInDOM(
    element,
    /* eslint-disable max-len */
    `button:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]),
              [href]:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]),
              input:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]),
              select:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]),
              textarea:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]),
              [tabIndex]:not([tabIndex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]),
              [contenteditable]:not([tabIndex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]):not(.p-disabled)`
    /* eslint-enable max-len */
  );

  let visibleFocusableElements = [];
  for (let focusableElement of focusableElements) {
    if (
      !!(
        focusableElement.offsetWidth ||
        focusableElement.offsetHeight ||
        focusableElement.getClientRects().length
      )
    ) {
      visibleFocusableElements.push(focusableElement);
    }
  }
  return visibleFocusableElements;
};

export const getNextFocusableElement = (
  element: HTMLElement,
  reverse = false
) => {
  const focusableElements = getFocusableElements(element);
  let index = 0;
  if (
    focusableElements &&
    focusableElements.length > 0 &&
    focusableElements[0].ownerDocument.activeElement
  ) {
    const focusedIndex = focusableElements.indexOf(
      focusableElements[0].ownerDocument.activeElement as HTMLElement
    );

    if (reverse) {
      if (focusedIndex == -1 || focusedIndex === 0) {
        index = focusableElements.length - 1;
      } else {
        index = focusedIndex - 1;
      }
    } else if (
      focusedIndex != -1 &&
      focusedIndex !== focusableElements.length - 1
    ) {
      index = focusedIndex + 1;
    }
  }

  return focusableElements[index];
};

export const getElementOuterDimension = (
  element: HTMLElement,
  dimensionType: 'width' | 'height',
  margin?: boolean
): number => {
  let outerDimension =
    dimensionType === 'width' ? element.offsetWidth : element.offsetHeight;

  if (margin) {
    let style = getComputedStyle(element);
    const firstMargin =
      dimensionType === 'width' ? style.marginLeft : style.marginTop;
    const secondMargin =
      dimensionType === 'width' ? style.marginRight : style.marginBottom;
    outerDimension += parseFloat(firstMargin) + parseFloat(secondMargin);
  }

  return outerDimension;
};

export const getDimension = (
  element: HTMLElement,
  dimensionType: 'width' | 'height'
): number => {
  let dimension =
    dimensionType === 'width' ? element.offsetWidth : element.offsetHeight;
  let style = getComputedStyle(element);

  const firstPadding =
    dimensionType === 'width' ? style.paddingLeft : style.paddingTop;
  const secondPadding =
    dimensionType === 'width' ? style.paddingRight : style.paddingBottom;
  const firstBorder =
    dimensionType === 'width' ? style.borderLeftWidth : style.borderTopWidth;
  const secondBorder =
    dimensionType === 'width'
      ? style.borderRightWidth
      : style.borderBottomWidth;

  dimension -=
    parseFloat(firstPadding) +
    parseFloat(secondPadding) +
    parseFloat(firstBorder) +
    parseFloat(secondBorder);

  return dimension;
};

export const getViewport = (): { width: number; height: number } => {
  const docElement = document.documentElement;
  const bodyElement = docElement.getElementsByTagName('body')[0];

  return {
    width:
      window.innerWidth || docElement.clientWidth || bodyElement.clientWidth,
    height:
      window.innerHeight || docElement.clientHeight || bodyElement.clientHeight,
  };
};

export const isElement = (obj: any) => {
  return typeof HTMLElement === 'object'
    ? obj instanceof HTMLElement
    : obj &&
        typeof obj === 'object' &&
        obj.nodeType === 1 &&
        typeof obj.nodeName === 'string';
};

export const appendChild = (element: any, target: any) => {
  if (isElement(target)) {
    target.appendChild(element);
  } else if (target && target.el && target.el.nativeElement) {
    target.el.nativeElement.appendChild(element);
  } else {
    throw 'Cannot append ' + target + ' to ' + element;
  }
};

export const getOffset = (
  element: Nullable<HTMLElement>
): { top: number; left: number } => {
  const rect = element!.getBoundingClientRect();

  return {
    top:
      rect!.top +
      (window.scrollY ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0),
    left:
      rect!.left +
      (window.scrollX ||
        document.documentElement.scrollLeft ||
        document.body.scrollLeft ||
        0),
  };
};

export const getParents = (element: any, parents: any = []): any => {
  return element['parentNode'] === null
    ? parents
    : getParents(element.parentNode, parents.concat([element.parentNode]));
};

export const findSingle = (element: any, selector: string): any => {
  if (element) {
    return element.querySelector(selector);
  }
  return null;
};

export const getScrollableParents = (element: any) => {
  let scrollableParents = [];

  if (element) {
    let parents = getParents(element);
    const overflowRegex = /(auto|scroll)/;
    const overflowCheck = (node: any) => {
      let styleDeclaration = window['getComputedStyle'](node, null);
      return (
        overflowRegex.test(styleDeclaration.getPropertyValue('overflow')) ||
        overflowRegex.test(styleDeclaration.getPropertyValue('overflowX')) ||
        overflowRegex.test(styleDeclaration.getPropertyValue('overflowY'))
      );
    };

    for (let parent of parents) {
      let scrollSelectors =
        parent.nodeType === 1 && parent.dataset['scrollselectors'];
      if (scrollSelectors) {
        let selectors = scrollSelectors.split(',');
        for (let selector of selectors) {
          let el = findSingle(parent, selector);
          if (el && overflowCheck(el)) {
            scrollableParents.push(el);
          }
        }
      }

      if (parent.nodeType !== 9 && overflowCheck(parent)) {
        scrollableParents.push(parent);
      }
    }
  }

  return scrollableParents;
};

export const isIOS = () =>
  /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any)?.MSStream;

export const isTouchDevice = () =>
  'ontouchstart' in window || navigator.maxTouchPoints > 0;

export const getWindowScrollTop = (): number => {
  let doc = document.documentElement;
  return (window.scrollY || doc.scrollTop) - (doc.clientTop || 0);
};

export const getWindowScrollLeft = (): number => {
  let doc = document.documentElement;
  return (window.scrollX || doc.scrollLeft) - (doc.clientLeft || 0);
};

export const getHiddenElementDimensions = (element: any): any => {
  let dimensions: any = {};
  element.style.visibility = 'hidden';
  element.style.display = 'block';
  dimensions.width = element.offsetWidth;
  dimensions.height = element.offsetHeight;
  element.style.display = 'none';
  element.style.visibility = 'visible';

  return dimensions;
};

export const absoluteTopPosition = (
  element: Nullable<HTMLDivElement>,
  target: HTMLElement
): void => {
  const elementDimensions = {
    width: element?.offsetWidth,
    height: element?.offsetHeight,
  };
  const elementOuterHeight = elementDimensions.height;
  const targetOuterWidth = target.offsetWidth;
  const targetOffset = target.getBoundingClientRect();
  const windowScrollTop = getWindowScrollTop();
  const windowScrollLeft = getWindowScrollLeft();
  let top, left;

  top = targetOffset.top + windowScrollTop - elementOuterHeight!;
  if (top < 0) {
    top = windowScrollTop;
  }

  left = targetOffset.left + windowScrollLeft;

  if (element) {
    element.style.top = top + 'px';
    element.style.left = left + targetOuterWidth / 2 + 'px';
  }
};

export const absolutePosition = (element: any, target: any): void => {
  let elementDimensions = element.offsetParent
    ? { width: element.offsetWidth, height: element.offsetHeight }
    : getHiddenElementDimensions(element);
  let elementOuterHeight = elementDimensions.height;
  let elementOuterWidth = elementDimensions.width;
  let targetOuterHeight = target.offsetHeight;
  let targetOuterWidth = target.offsetWidth;
  let targetOffset = target.getBoundingClientRect();
  let windowScrollTop = getWindowScrollTop();
  let windowScrollLeft = getWindowScrollLeft();
  let viewport = getViewport();
  let top, left;

  if (
    targetOffset.top + targetOuterHeight + elementOuterHeight >
    viewport.height
  ) {
    top = targetOffset.top + windowScrollTop - elementOuterHeight;
    element.style.transformOrigin = 'bottom';

    if (top < 0) {
      top = windowScrollTop;
    }
  } else {
    top = targetOuterHeight + targetOffset.top + windowScrollTop;
    element.style.transformOrigin = 'top';
  }

  if (targetOffset.left + elementOuterWidth > viewport.width) {
    left = Math.max(
      0,
      targetOffset.left +
        windowScrollLeft +
        targetOuterWidth -
        elementOuterWidth
    );
  } else {
    left = targetOffset.left + windowScrollLeft;
  }

  element.style.top = top + 'px';
  element.style.left = left + 'px';
};

export const getClosestRelativeElement = (
  el: HTMLElement
): Nullable<HTMLElement> => {
  if (!el) {
    return;
  }

  return getComputedStyle(el).getPropertyValue('position') === 'relative'
    ? el
    : getClosestRelativeElement(el.parentElement!);
};

export const relativePosition = (
  element: HTMLElement,
  target: HTMLElement
): void => {
  const elementDimensions = element.offsetParent
    ? { width: element.offsetWidth, height: element.offsetHeight }
    : getHiddenElementDimensions(element);
  const targetHeight = target.offsetHeight;
  const targetOffset = target.getBoundingClientRect();
  const windowScrollTop = getWindowScrollTop();
  const windowScrollLeft = getWindowScrollLeft();
  const viewport = getViewport();
  const relativeElement = getClosestRelativeElement(element);
  const relativeElementOffset = relativeElement?.getBoundingClientRect() || {
    top: -1 * windowScrollTop,
    left: -1 * windowScrollLeft,
  };
  let top: number, left: number;

  if (
    targetOffset.top + targetHeight + elementDimensions.height >
    viewport.height
  ) {
    top =
      targetOffset.top - relativeElementOffset.top - elementDimensions.height;
    element.style.transformOrigin = 'bottom';
    if (targetOffset.top + top < 0) {
      top = -1 * targetOffset.top;
    }
  } else {
    top = targetHeight + targetOffset.top - relativeElementOffset.top;
    element.style.transformOrigin = 'top';
  }

  const horizontalOverflow =
    targetOffset.left + elementDimensions.width - viewport.width;
  const targetLeftOffsetInSpaceOfRelativeElement =
    targetOffset.left - relativeElementOffset.left;
  if (elementDimensions.width > viewport.width) {
    // element wider than viewport and cannot fit on screen (align at left side of viewport)
    left = (targetOffset.left - relativeElementOffset.left) * -1;
  } else if (horizontalOverflow > 0) {
    // element wider than viewport but can be fit on screen (align at right side of viewport)
    left = targetLeftOffsetInSpaceOfRelativeElement - horizontalOverflow;
  } else {
    // element fits on screen (align with target)
    left = targetOffset.left - relativeElementOffset.left;
  }

  element.style.top = top + 'px';
  element.style.left = left + 'px';
};

export const addClass = (element: any, className: string): void => {
  if (element.classList) {
    element.classList.add(className);
  } else {
    element.className += ' ' + className;
  }
};

export const elementHasClass = (element: any, className: string): boolean => {
  if (element && className) {
    if (element.classList) {
      return element.classList.contains(className);
    } else {
      return new RegExp('(^| )' + className + '( |$)', 'gi').test(
        element.className
      );
    }
  }

  return false;
};

export const getElementIndex = (element: any): number => {
  let children = element.parentNode.childNodes;
  let num = 0;
  for (let i = 0; i < children.length; i++) {
    if (children[i] == element) {
      return num;
    }
    if (children[i].nodeType == 1) {
      num++;
    }
  }
  return -1;
};

export const getElementComputedStyle = (
  element: Element,
  property: string,
  isNumeric?: boolean
): any => {
  const style = getComputedStyle(element);
  const prop = style ? style.getPropertyValue(property) : undefined;
  return prop && isNumeric ? +prop.replace(/[^0-9]/g, '') : prop;
};

export const isDOMElementVisible = (el: any) => el?.offsetParent !== null;
