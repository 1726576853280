import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { Playlist } from 'src/app/core/types/playlist.types';

@Injectable({
  providedIn: 'root'
})
export class PlaylistService {

  constructor(private _http: HttpClient) { }

  getPlaylists(): Observable<Playlist[]> {
    return this._http.get<Playlist[]>(`${environment.API_URL}/Playlist`)
  }
}
