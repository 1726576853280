import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PlaylistService } from 'src/app/services/playlist/playlist.service';
import {
  getPlaylists,
  getPlaylistsFailure,
  getPlaylistsSuccess,
} from './playlist.actions';
import { catchError, map, mergeMap, of } from 'rxjs';

@Injectable()
export class PlaylistEffects {
  getPlaylistEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(getPlaylists),
      mergeMap((_action) =>
        this._playlistService.getPlaylists().pipe(
          map((data) => getPlaylistsSuccess({ data })),
          catchError((error) => of(getPlaylistsFailure({ error })))
        )
      )
    )
  );

  constructor(
    private _actions$: Actions,
    private _playlistService: PlaylistService
  ) {}
}
