import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PlaylistState } from './playlist.reducer';

export const selectPlaylistState =
  createFeatureSelector<PlaylistState>('playlist');

export const selectPlaylists = createSelector(
  selectPlaylistState,
  (state: PlaylistState) => state.data
);

export const selectPlaylistError = createSelector(
  selectPlaylistState,
  (state: PlaylistState) => state.error
);

export const selectSelectedPlaylist = createSelector(
  selectPlaylistState,
  (state: PlaylistState) => state.selectedPlaylist
);

export const selectSelectedClip = createSelector(
  selectPlaylistState,
  (state: PlaylistState) => state.selectedClip
);
