import { createAction, props } from '@ngrx/store';
import { Playlist, PlaylistClip } from 'src/app/core/types/playlist.types';

export const getPlaylists = createAction('[Playlist] Get playlists');
export const getPlaylistsSuccess = createAction(
  '[Playlist] Get playlist success',
  props<{ data: Playlist[] }>()
);
export const getPlaylistsFailure = createAction(
  '[Playlist] Get playlist failure',
  props<{ error: any }>()
);
export const selectPlaylist = createAction(
  '[Playlist] Select playlist',
  props<{ playlist: Playlist }>()
);
export const selectClip = createAction(
  '[Playlist] Select clip',
  props<{ clip: PlaylistClip }>()
);
